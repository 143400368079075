import styled from 'styled-components';

const Layout = styled.div`
  Padding: 0 30px;
  width: 100%;
  float: left;
  margin-bottom: 50px;
  margin-top: ${({marginTop}) => marginTop ? '-75px' : '0px' };
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: ${({color}) => color ? 'transparent' : 'white' };
  float: left;
`;

const EditorContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
`;

export { Layout, FormContainer, EditorContainer };